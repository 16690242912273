<template>
	<div >
		<div class="d-flex align-items-center justify-content-around mb-4">
			<div class=" d-flex flex-1">
				<button class="btn btn-light " @click="goBack">
					<font-awesome-icon class="m-0" :icon="['fas', 'chevron-left']" />
					{{$t('planes.back')}}
				</button>
			</div>
			<h2 class="text-center flex-1  m-0">{{ $t('planes.changes.title') }}</h2>
			<div class="flex-1"></div>
		</div>
		<div class="changes">
			<plane-change-card :change="change" :key="change.id"  v-for="change in planeChanges" @reload="loadChanges" />
		</div>
	</div>
</template>

<script>
import PlaneChangeCard from "@/components/plane/PlaneChangeCard.vue";


export default {
	name: 'PlaneChangesView',
	components: { PlaneChangeCard },
	computed: {
		cancelBtnTitle() {
			return this.$t('planes.cancelButton')
		}
	},
	data() {
		return {
			/** @type {PlaneChange[]} */
			planeChanges: [],
		};
	},
	props: {
		msgs: Object
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		async loadChanges(){
			this.planeChanges = await this.$rest.loadAllPlaneChanges();
			if(this.planeChanges.length == 0){
				this.$router.replace({name: 'planes'});
			}
		}
	},
	async beforeMount() {
		await this.loadChanges();
	},
}

</script>

<style scoped>
.changes {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
}
</style>
