<template>
	<div class="change" v-bind:key="c.id">
		<div class="change-header">
			<div class="plane-info-box">
				<span class="label">{{$t('planes.changes.changedPlane')}}:</span>
				<div class="plane-info">
					<img alt="" :src="image" />
					<div>
						<span class="regNum">{{c.userPlane.plane.registrationNumber}}</span>
						<span class="name">{{c.userPlane.plane.producer}} {{c.userPlane.plane.model}}</span>
					</div>
				</div>
			</div>
			<div class="user-info-box">
				<span class="label">{{ $t("planes.changes.changer") }}:</span>
				<div class="user-info">
					<avatar :user="c.userPlane.user" class="avatar" />
					<div>
						<span class="name" v-if="c.userPlane.user.name">{{c.userPlane.user.name}}</span>
						<span class="email">@{{c.userPlane.user.username}}</span>
					</div>
				</div>
			</div>
			<div class="actions">
				<TextInput :msgs="{}" :value="c.comment" :placeholder="$t('planes.changes.comment')" class="form-control comment" @change="(v) => c.comment = v" />
				<div class="btn-group" role="group" aria-label="Basic mixed styles example">
					<button type="button" class="btn btn-danger" @click="approve(false)">
						<font-awesome-icon class="m-0" icon="times" />
					</button>
					<button type="button" class="btn btn-success" @click="approve(true)">
						<font-awesome-icon class="m-0" icon="check" />
					</button>
				</div>
			</div>
		</div>
		<div class="change-body">
			<div class="value-box">
				<h6>
					{{$t(`plane.field.${c.field}`)}}
					<span class="text-danger">({{$t('planes.changes.oldValue')}})</span>
				</h6>
				<div class="value">
					<span v-html="oldValue"></span>
				</div>
			</div>
			<div class="middle-arrow">
				<font-awesome-icon icon="arrow-right" />
			</div>
			<div class="value-box">
				<h6>
					{{$t(`plane.field.${c.field}`)}}
					<span class="text-success">({{$t('planes.changes.newValue')}})</span>
				</h6>
				<div class="value">
					<span v-html="newValue"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getPlaneIcon } from "@/plugins/planeUtils";
import Avatar from "@/components/profile/avatar/Avatar.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import _ from "lodash";

export default {
	name: 'PlaneChangeCard',
	components: { Avatar, TextInput },
	emits: ["reload"],
	props: {
		change: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			/** @type {PlaneChange} */
			c: this.change,
		}
	},
	methods: {
		async approve(approved) {
			await this.$rest.approvePlaneChange(this.c.id, approved, this.c.comment);
			this.$emit("reload");
		},
		parseValue(v) {
			if(!v) return "";

			//Exceptions
			if(this.c.field === "beaconType") return this.$t(`beaconType.${v}`);
			if(this.c.field === "gravityPoints") {
				v = JSON.parse(v);
				return v.map(p => `(${p.xValue},${p.yValue})`).join("<br />");
			}

			if(typeof v === "number" || typeof v === "string") return v;
			if(_.isArray(v)) return v.join(", ");
			if(typeof v === "object") return JSON.stringify(v, null, 2);
			if(typeof v === "boolean") return v ? this.$t("true") : this.$t("false");

			return "";
		}
	},
	computed: {
		image() {
			return getPlaneIcon(this.c.userPlane.plane.beaconType)
		},
		oldValue(){
			try{
				return this.parseValue(this.c.userPlane.plane[this.c.field]);
			}catch (e) {
				return this.c.userPlane.plane[this.c.field];
			}
		},
		newValue(){
			try{
				let v = JSON.parse(this.c.newValue);
				return this.parseValue(v);
			}catch (e) {
				return this.c.newValue;
			}
		}
	}
}
</script>

<style scoped lang="css" >
.change {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #f8f9fa;
	border-radius: 1rem;
	width: 100%;
	padding: 1rem;
	gap: 2rem;
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
}

.change .change-header {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 1rem;
}

.change .change-header .plane-info-box {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 0;
}
.change .change-header .user-info-box {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0;
}


.label {
	font-size: 0.7rem;
	text-transform: uppercase;
	color: #6c757d;
}

.change .change-header .plane-info {
	flex: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5rem;
}

.change .change-header .plane-info img {
	width: 42px;
	height: 42px;
	rotate: 45deg;
}

.change .change-header .plane-info .regNum {
	display: block;
	font-weight: bold;
	font-size: 1rem;
	text-transform: uppercase;
}

.change .change-header .plane-info .name {
	display: block;
	font-size: 0.8rem;
}

.change .change-header .user-info {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
}

.change .change-header .user-info .avatar {
	width: 42px;
	height: 42px;
}
.change .change-header .user-info .name {
	display: block;
	font-weight: bold;
	font-size: 1rem;
	text-overflow: ellipsis;
	max-width: 16rem;
	overflow: hidden;
}

.change .change-header .user-info .email {
	display: block;
	font-size: 0.8rem;
	text-overflow: ellipsis;
	max-width: 14rem;
	overflow: hidden;
}

.change .change-header .actions {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.5rem;
}

.change .change-header .actions  .comment {
	width: 8rem;
}

.change .change-header .actions button {
	width: 2.8rem;
}

.change .change-body {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	gap: 2rem;
}
.change .change-body .middle-arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	margin-top: 30px; /* the height of the h6 */
}

.change .change-body .value-box {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.change .change-body .value-box h6 {
	font-weight: bold;
}

.change .change-body .value-box .value {
	display: block;
	font-size: 1rem;
	font-weight: bold;
	background: white;
	border-radius: 0.5rem;
	padding: 0.5rem;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	width: 100%;
	flex: 1;
	min-height: 40px;
}

@media screen and (max-width: 768px) {
	.change .change-header .plane-info {
		flex-direction: row;
	}
	.change .change-header .plane-info .regNum {
		font-size: 0.8rem;
	}

	.change .change-header .user-info {
		flex-direction: row;
	}
	.change .change-header .user-info .name {
		font-size: 0.8rem;
		text-overflow: ellipsis;
		max-width: 12rem;
		overflow: hidden;
	}
	.change .change-header .user-info .email {
		text-overflow: ellipsis;
		max-width: 12rem;
		overflow: hidden;
	}


	.change .change-header .actions {
		flex-direction: column-reverse;
		gap: 0.3rem;
	}

	.change .change-header .actions .comment {
		width: 6rem;
		font-size: 0.7rem;
	}

	.change .change-header .actions .btn-group {
		width: 6rem;
		font-size: 0.8em;
	}

	.change .change-header .actions .btn-group button {
		font-size: 0.8em;
		padding: 0.4rem 0;
	}

	.change .change-body {
		flex-direction: column;
		gap: 1rem;
	}
	.change .change-body .middle-arrow {
		rotate: 90deg;
		margin: 0;
		font-size: 1.3rem;
	}
	.change .change-body .value-box .value {
		font-size: 0.8rem;
	}

	.change .change-body .value-box h6 {
		font-size: 0.8rem;
	}
}

@media screen and (max-width: 568px) {
	.change {
		flex-direction: column;
	}
	.change .change-header {
		flex-direction: row;
	}
	.change .change-header .plane-info {
		flex-direction: column;
		justify-content: flex-start;
		text-align: center;
	}

	.change .change-header .plane-info .name {
		font-size: 0.6rem;
	}
	.change .change-header .plane-info img {
		width: 32px;
		height: 32px;
	}
	.change .change-header .user-info {
		flex-direction: column;
		justify-content: flex-start;
		text-align: center;
	}
	.change .change-header .user-info .avatar {
		width: 32px;
		height: 32px;
	}

	.change .change-header .user-info .email {
		font-size: 0.6rem;
	}

	.change .change-header .actions {
		flex-direction: column-reverse;
		gap: 0.3rem;
	}


	.change .change-header .actions .btn-group {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.change .change-header .actions button {
		width: 100%;
	}


	.change .change-header .actions .comment {
		width: 100%;
	}


	.change .change-body .value-box h6 {
		font-size: 0.8rem;
	}
}


@media screen and (max-width: 460px) {
	.change {
		flex-direction: column;
	}
	.change .change-header {
		flex-direction: column;
	}

	.change .change-header .actions {
		flex-direction: row;
	}

	.change .change-header .actions .btn-group {
		width: 8rem;
	}
	.change .change-header .actions button {

	}


	.change .change-header .actions .comment {
		width: 100%;
	}


}


</style>
